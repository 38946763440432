import React from "react";
import PortableText from "../PortableText";
import { styles } from "./styles";
import {
  LinksPage as LinksPageProps,
  LinkSection as LinkSectionInterface
} from "../../interfaces/linksPage";
import LinkSection from "./components/LinkSection";
import Figure from "../Figure";
import Page from "../Page";
import { BodyPortableText } from "../../interfaces/sanity";

const LinksPage: React.FC<LinksPageProps> = ({
  _rawBody,
  title,
  mainImage,
  _rawLinks
}) => {
  return (
    <Page title={title}>
      <div css={styles.container}>
        <div css={[styles.content, mainImage && styles.hasImage]}>
          {_rawBody && <PortableText blocks={_rawBody} />}
          {mainImage && mainImage.asset && <Figure node={mainImage} />}
        </div>
        {_rawLinks && (
          <div>
            {_rawLinks.map(
              (section: BodyPortableText & LinkSectionInterface, i) => (
                <LinkSection {...section} key={section._key} />
              )
            )}
          </div>
        )}
      </div>
    </Page>
  );
};

export default LinksPage;
