import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
import { breakpointMixins } from "../../styles/breakpoints";
import { textStyles } from "../../styles/type";

const { spacing, contentWidth } = variables;
export const styles: EmotionStyles = {
  content: {
    display: "flex",
    flexDirection: "column-reverse",
    ...textStyles.bodyM,

    [breakpointMixins.md]: {
      flexDirection: "row"
    },
    [breakpointMixins.lg]: {
      "& p": {
        maxWidth: contentWidth
      }
    }
  },
  hasImage: {
    "& figure": {
      minWidth: 250,
      margin: "0 auto",
      [breakpointMixins.md]: {
        width: "100%",
        margin: `0 0 0 ${spacing.medium}`
      },
      [breakpointMixins.lg]: {
        margin: `0 0 0 ${spacing.large}`
      }
    }
  }
};

export const linkSectionStyles: EmotionStyles = {
  linkList: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
    maxWidth: contentWidth
  },
  linkListItem: {
    marginBottom: spacing.large
  }
};

export const linkItemStyles: EmotionStyles = {
  container: {
    wordBreak: "break-word",
    "& span": {
      display: "block",
      marginBottom: spacing.small
    },
    "& a": {
      display: "block"
    }
  }
};
