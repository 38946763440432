import { isFuture } from "date-fns";
import { BlogPostInterface } from "../interfaces/blogPost";
import { TransistorEpisodeInterface } from "../interfaces/podcasts";
import { EpisodeResult, PostResult, ProductResult } from "../pages/search";
import { BodyPortableText, SanitySlug } from "../interfaces/sanity";
import { ProductInterface } from "../interfaces/product";

export const unflattenEpisodeResults = (
  results: Array<EpisodeResult>,
  episodes: Array<TransistorEpisodeInterface>
) => {
  const ids = results.map(episode => episode.id);
  const searchResults = episodes.filter(episode =>
    ids.includes(episode.id.toString())
  );
  return searchResults;
};

export const unflattenPostResults = (
  results: Array<PostResult>,
  posts: Array<BlogPostInterface>
) => {
  const ids = results.map(post => post.id);
  const searchResults = posts.filter(post => ids.includes(post.id));
  return searchResults;
};

export const unflattenProductResults = (
  results: Array<ProductResult>,
  products: Array<ProductInterface>
) => {
  const ids = results.map(product => product.id);
  const searchResults = products.filter(product => ids.includes(product.id));
  return searchResults;
};

export function mapEdgesToNodes(data: { edges: Array<{ node: any }> }) {
  if (!data.edges) return [];
  return data.edges.map(edge => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }: { slug?: SanitySlug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({
  publishedAt
}: {
  publishedAt: Date;
}) {
  return !isFuture(publishedAt);
}

export function getBlogUrl(slug: SanitySlug) {
  return `/blog/${slug.current || slug}/`;
}

export function toPlainText(blocks?: Array<BodyPortableText>) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map(child => child.text).join("");
    })
    .join("\n\n");
}
