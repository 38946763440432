import React, { Fragment } from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import { toPlainText } from "../helpers/content";
import LinksPage from "../components/LinksPage";
import { LinksPage as LinksPageInterface } from "../interfaces/linksPage";
import { SiteInterface } from "../pages";

export const query = graphql`
  query CmsLinksPageTemplateQuery($id: String!) {
    page: sanityLinksPage(id: { eq: $id }) {
      id
      title
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      _rawLinks(resolveReferences: { maxDepth: 5 })
    }
    site: sanitySiteSettings {
      title
      keywords
    }
  }
`;

interface LinksPageTemplateProps {
  data: {
    page: LinksPageInterface;
    site: Omit<SiteInterface, "description">;
  };
  errors: Array<GraphQLError>;
}

const LinksPageTemplate: React.FC<LinksPageTemplateProps> = ({
  data,
  errors
}) => {
  const page = data && data.page;
  const site = data && data.site;
  return (
    <Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {page && site && (
        <SEO
          title={`${page.title || "Untitled"} | ${site.title}`}
          description={toPlainText(page._rawExcerpt)}
          keywords={site.keywords}
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}
      {page && <LinksPage {...page} />}
    </Fragment>
  );
};

export default LinksPageTemplate;
