import React from "react";
import { LinkSectionLink } from "../../../interfaces/linksPage";
import { linkItemStyles as styles } from "../styles";

const LinkItem: React.FC<LinkSectionLink> = ({ description, linkUrl }) => {
  return (
    <div css={styles.container}>
      <span>{description}</span>
      <a href={linkUrl} title={description} target="_blank">
        {linkUrl}
      </a>
    </div>
  );
};

export default LinkItem;
