import React from "react";
import {
  LinkSection as LinkSectionProps,
  LinkSectionLink
} from "../../../interfaces/linksPage";
import PortableText from "../../PortableText";
import LinkItem from "./LinkItem";
import { linkSectionStyles as styles } from "../styles";
import Heading from "../../Heading";
import { BodyPortableText } from "../../../interfaces/sanity";

const LinkSection: React.FC<BodyPortableText & LinkSectionProps> = ({
  linkSectionTitle,
  linkSectionDescription,
  links
}) => {
  return (
    <div>
      {linkSectionTitle && (
        <Heading headingLevel="h2">{linkSectionTitle}</Heading>
      )}
      {linkSectionDescription &&
        linkSectionDescription.map((descriptionSection, i) => {
          return (
            <PortableText
              blocks={descriptionSection}
              key={`linkSection_${i}`}
            />
          );
        })}
      {links && (
        <ul css={styles.linkList}>
          {links.map((link: LinkSectionLink, i: number) => (
            <li css={styles.linkListItem} key={link._key}>
              <LinkItem {...link} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LinkSection;
